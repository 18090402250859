.app {
  display: flex;
  min-height: 100vh;
}

.bg {
  flex: 1;
  background: #3a94b9;
  background-image: url('./blue-space.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 3px 30px rgba(0, 0, 0, .2);
  background-image: url('./ash-connell.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 8px solid white;
}

.content {
  width: 520px;
  text-align: center;
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}

.bio {
  max-width: 310px;
}

.btn {
  display: inline-block;
  height: 60px;
  border-radius: 30px;
  background: #3a94b9;
  line-height: 60px;
  color: white;
  text-decoration: none;
  padding: 0 45px;
  font-weight: 600;
  margin: 0 0 30px;
  transition: all .15s ease-out;
}

.btn:hover {
  transform: scale(1.05);
  box-shadow: 0 3px 7px #00000021;
  background: #44a2c8;

}

.social {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

.icon {
  transition: all .15s ease-out;
}

.icon:hover {
  fill: #3a94b9;
}

@media all and (max-width: 1034px) {

  .app {
    flex-direction: column;
  }

  .content {
    width: inherit;
  }

  .bg {
    flex: inherit;
    height: 420px;
  }
}
